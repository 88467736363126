
import { Prop, Vue, Component, Watch, Model } from 'vue-property-decorator'
import server, { TeamUse } from '@/api/team-user'

// 团队选择组件
@Component({ name: 'TeamSelector', components: {}, mixins: [] })
export default class TeamSelector extends Vue {
  // 最新的vue版本一定要指定value 与 input
  @Model('input')
  readonly value?: number|string

  curValue = 0

  @Prop({
    type: String,
    default: '输入团队名称或编号'
  })
  placeholder!: string

  @Prop({
    type: Number,
    default: undefined
  })
  userId?: number

  @Prop({
    type: Boolean,
    default: false
  })
  readonly!: boolean

  loading = false

  datas: Array<any> = []

  onChange (newValue: number) {
    this.$emit('input', newValue)
    this.curValue = newValue
  }

  onSelect (newValue: any) {
    this.$emit('input', newValue.value)
    this.curValue = newValue.value
  }

  initData (userId: number) {
    this.$emit('input', undefined)
    this.curValue = -1
    this.userId = userId
    if (this.userId) {
      this.loadDataFromServer()
    }
  }

  // 加载下拉框选项
  loadDataFromServer () {
    this.loading = true
    const that = this as any
    const queryData = {
      page: 1,
      pageSize: 100,
      state: 1,
      userId: this.userId,
      sorts: []
    }
    server
      .queryTeamListByQuery(queryData)
      .then((respose) => {
        const items = respose.data!.items
        that.loading = false
        this.datas = items.map(item => {
          return {
            value: item.teamId,
            label: item.teamName + `(${item.teamCode})`
          }
        })
      }).catch(err => {
        this.$Notice.error({ title: '错误', desc: '加载团队数据失败:' + err.message })
      })
  }
}
