
import { showModal } from '@/libs/modal-helper'
import modal from './pic-verify-modal.vue'

export interface PicCheckResult{
  key: string;
  code: number;
}
/**
  * 显示图片验证对话框
  * @returns
  */
export default function showPicVerifyModal (): Promise<PicCheckResult> {
  return new Promise<PicCheckResult>((resolve, reject) => {
    showModal<PicCheckResult>(modal, {
      props: {
      }
    }, true, (res: PicCheckResult) => {
      resolve(res)
      return new Promise<void>((resolve, reject) => resolve())
    }, () => {
      reject(new Error('用户取消'))
    })
  })
}
