
import { Vue, Component } from 'vue-property-decorator'
import { Form } from 'view-design'
import showSelectTeamModal from './components/choose-team-modal'
import { User } from '@/api/user'
import { dispatchClearToken, dispatchHandleLogin, dispatchUpdateUserInfo } from '@/store'
import showPicVerifyModal, { PicCheckResult } from './components/pic-verify-modal'
import showForgetPasswordModal from './components/forget-password-modal'
import security from '@/api/security'
import localStorage from '@/libs/local-storage'

@Component({ name: 'Login', components: {} })
export default class extends Vue {
  imgLableList = [
    '微服务框架',
    '混合云架构',
    '可视化开发',
    '跨平台',
    '代码级定制'
  ];

  form: User = {};
  loginUser: User = {};

  showPicCode = false;

  showChoose = false;

  // 自定义表单验证
  validatePwd = (rule: any, value: string, callback: any) => {
    const patrn = /^(\w){6,20}$/
    if (!value || value === '') {
      callback(new Error('请输入密码'))
    } else if (value.length < 6) {
      callback(new Error('密码长度不能少于6位'))
    }
    callback()
  };

  // 自定义表单验证
  validateUserLoginName = (rule: any, value: string, callback: any) => {
    if (!value || value === '') {
      callback(new Error('请输入登录账号'))
    }
    callback()
  };

  // 表单验证规矩
  ruleUser = {
    userLoginName: [{ validator: this.validateUserLoginName, trigger: 'blur' }],
    pwd: [{ validator: this.validatePwd, trigger: 'blur' }]
  };

  // 表单验证
  login () {
    const checkform = this.$refs.formRef as Form
    checkform.validate((valid) => {
      if (valid) {
        this.doLogin()
      } else {
        this.$Message.error({ content: '表单错误，请按表单要求输入内容！', duration: 3 })
      }
    })
  }

  // 检查登录用户是否已注册
  doLogin () {
    let piccode = -1
    let pickey = ''
    dispatchClearToken()
    showPicVerifyModal()
      .then(({ key, code }: PicCheckResult) => {
        piccode = code
        pickey = key
        return { piccode: code, pickey: key }
      })
      .then(() => {
        return security.getUserTeamListByLoginName(this.form.userLoginName!, this.form.pwd!, pickey, piccode)
      })
      .then((resp) => {
        // 显示团队选择弹窗
        return showSelectTeamModal(resp.data!.userId, resp.data!.list)
      })// 执行登入
      .then((teamCode) => {
        return dispatchHandleLogin({
          userName: this.form.userLoginName,
          password: this.form.pwd,
          teamCode: teamCode,
          loginKey: pickey,
          validCode: piccode
        })
      })
      .then(() => {
        return security.clearRecordCache(localStorage.loadUserLastTeamId() || 0)
      })
      .then((resp) => {
        return dispatchUpdateUserInfo(true)
      })
      .then((user) => {
        this.$router.push('/project')
      })
      .catch((err) => {
        this.$Notice.error({ title: '提示', desc: '登录失败：' + err.message })
      })
  }

  // 忘记密码
  forgetPass () {
    dispatchClearToken()
    showForgetPasswordModal()
  }
}
