
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { BaseModal } from '@/libs/modal-helper'
import SecurityService from '@/api/security'

/**
 * 基于图片的验证组件
 */
@Component({
  name: 'picVerifyModal'
})
export default class PicVerifyModal extends Vue implements BaseModal {
    // 显示状态
    visiable=false

    img: any=null;
    key?: string
    angle=0

    /// 创建方法
    created () {
      this.requestPic()
    }

    onInput (value: number) {
      this.angle = -value
    }

    /**
     * 拖放完成
     */
    onChange (value: number) {
      SecurityService.requestCheckCode(this.key!, value)
        .then((response) => {
          this.$emit('onOk', { key: this.key, code: response.data })
        }).catch(() => {
          this.$Message.error('验证失败')
          this.requestPic()
        })
    }

    hideFormat () {
      return null
    }

    /**
     * 请求验证图片
     */
    requestPic () {
      SecurityService.requestCheckPic()
        .then((response) => {
          this.img = response.data
          this.key = response.headers.x_xbg_logincode
        }).catch((err) => {
          this.$Message.error('请求验证图片失败:' + err.message)
        })
    }

    show (): void {
      this.visiable = true
    }

    close (): void {
      this.visiable = false
    }

    doSave (): void {
      this.$emit('onOk')
    }

    doCancel (): void {
      this.$emit('onCancel')
    }
}
