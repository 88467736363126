
import { Vue, Component } from 'vue-property-decorator'
import { BaseModal } from '@/libs/modal-helper'
import security from '@/api/security'
import { Form } from 'view-design'
import showPicVerifyWithCheckCodeModal from './pic-verify-with-check-code-modal'
import { dispatchClearToken } from '@/store'

 /**
  * 忘记密码对话框
  */
 @Component({
   name: 'forgetPasswordModal'
 })
export default class ForgetPasswordModal extends Vue implements BaseModal {
   // 显示状态
   visiable=false

   formData={
     phone: '',
     checkCode: '',
     pwd: '',
     repwd: ''
   }

   // 是否首次点击验证码
   firstCheckCodeFlag = true
   // 取得下一个验证码的时间间隔
   getCheckCodeInterval=0

   // 自定义表单验证
   validatePwd = (rule: any, value: string, callback: any) => {
     const patrn = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/

     if (!value || value === '') {
       callback(new Error('请输入确认密码'))
     } else if (value.length < 6) {
       callback(new Error('密码长度不能少于6位'))
     } else if (!patrn.test(value)) {
       callback(new Error('密码格式错误,至少要有字母与数字'))
     } else {
       callback()
     }
   }

   // 检查确认密码
   checkRePwd=(rule: any, value: string, callback: any) => {
     if (!value || value === '') {
       callback(new Error('请输入确认密码'))
     } else if (value !== this.formData.pwd) {
       callback(new Error('输入的两次密码不一致'))
     } else {
       callback()
     }
   }

   // 表单检查规则
   rules={
     phone: [
       { required: true, message: '手机号码不允许为空', trigger: 'blur' },
       { pattern: /^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/, message: '手机号码格式有误', trigger: 'blur' }
     ],
     checkCode: [
       { required: true, message: '验证码不允许为空', trigger: 'blur' }
     ],
     pwd: [
       { validator: this.validatePwd, trigger: 'blur' }
     ],
     repwd: [
       { validator: this.checkRePwd, trigger: 'blur' }
     ]
   }

   // 发送短信验证
   sendSmsCheckCode () {
     if (!this.formData.phone || !/^1(?:3\d|4[4-9]|5[0-35-9]|6[67]|7[013-8]|8\d|9\d)\d{8}$/.test(this.formData.phone)) {
       this.$Message.error('请先填写手机号码!')
       return
     }
     dispatchClearToken()
     showPicVerifyWithCheckCodeModal(this.formData.phone).then(() => {
       const phoneTitle = this.formData.phone.substring(0, this.formData.phone.length - 4)
       this.firstCheckCodeFlag = false
       this.$Message.info(`短信已经发下到您手机${phoneTitle}****，请注意查收`)
       this.getCheckCodeInterval = 20
       const that = this
       const hander = setInterval(() => {
         that.getCheckCodeInterval--
         if (that.getCheckCodeInterval === 0) {
           clearInterval(hander)
         }
       }, 1000)
     }).catch((err) => {
       this.$Message.error('短信发送错误:' + err.message)
     })
   }

   show (): void {
     this.visiable = true
   }

   close (): void {
     this.visiable = false
   }

   doSave (): void {
     const form = this.$refs.form as Form
     form.validate((valid) => {
       if (!valid) return
       security.forgetPassword(this.formData.phone, this.formData.pwd, this.formData.checkCode)
         .then(() => {
           // 保存密码操作成功
           this.$Message.success('密码修改成功')
           this.$emit('onOk')
         })
         .catch((err) => {
           this.$Message.error('修改密失败:' + err.message)
         })
     })
   }

   doCancel (): void {
     this.$emit('onCancel')
   }
}
