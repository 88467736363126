import { showModal } from '@/libs/modal-helper'
import modal from './pic-verify-with-check-code-modal.vue'

/**
  * 图片验证发送验证码的弹出对话框
  * @returns
  */
export default function showPicVerifyWithCheckCodeModal (phone: string): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    showModal<void>(modal, {
      props: {
        phone: phone
      }
    }, true, () => {
      resolve()
      return new Promise<void>((resolve, reject) => resolve())
    }, () => {
      reject(new Error('用户取消'))
    })
  })
}
