
import { Prop, Vue, Component, Watch } from 'vue-property-decorator'
import { BaseModal } from '@/libs/modal-helper'
import { Form } from 'view-design'
import teamServer, { Team } from '@/api/team'
import teamUserServer, { TeamUse } from '@/api/team-user'
import security from '@/api/security'

@Component({
  name: 'createTeamModal'
})
export default class CreateTeamModal extends Vue implements BaseModal {
  // 显示状态
  visiable = false;
  formData={
    teamName: '',
    teamCode: ''
  }

  /**
   * 当前的用户id
   */
  @Prop({
    type: Number,
    required: true
  })
  userId!: number;

  validateTeamName = (rule: any, value: any, callback: any) => {
    if (!this.formData.teamName || this.formData.teamName === '') {
      callback(new Error('团队名称不能为空'))
      return
    }
    teamServer.checkNewTeamName(this.formData.teamName).then((res) => {
      if (res.code === 0) {
        if (res.data) {
          callback()
        } else {
          callback(new Error('团队名称不能重复'))
        }
      }
    })
  };

  // 新增规则
  teamNameRule = [{ required: true, validator: this.validateTeamName, trigger: 'blur' }];

  // 加入规则
  teamCodeRule =[{ required: true, message: '团队编号不能为空', trigger: 'blur' }]

  // 对话框模式
  @Prop({
    type: Boolean,
    default: true,
    required: true
  })
  isCreateMode!: boolean;

  show (): void {
    this.visiable = true
  }

  close (): void {
    this.visiable = false
  }

  // 数据保存操作
  doSave (): void {
    const form = this.$refs.form as Form
    form.validate((valid) => {
      if (!valid) {
        this.$Message.error('数据校验失败,请检查!')
        return
      }

      const res = this.isCreateMode ? this.createTeam() : this.joinTeam()
      res
        .then((team) => {
          this.$emit('onOk', team)
        })
        .catch((err) => {
          this.$Message.error(err.message)
        })
    })
  }

  doCancel (): void {
    this.$emit('onCancel')
  }

  // 创建一个新的团队
  createTeam (): Promise<Team> {
    return new Promise<Team>((resolve, reject) => {
      security
        .createNewTeam(this.formData.teamName!, this.userId)
        .then((resp) => {
          this.$Notice.success({
            title: '成功',
            desc: `团队${resp.data?.name}创建成功，编号为${resp.data?.code},您当前是这个团队的管理员`
          })
          resolve(resp.data!)
        })
        .catch((err) => {
          reject(new Error('团队创建失败:' + err.message))
        })
    })
  }

  // 加入一个团队
  joinTeam (): Promise<Team> {
    return new Promise<Team>((resolve, reject) => {
      security
        .createTeamUser({
          teamCode: this.formData.teamCode,
          userId: this.userId
        })
        .then((response) => {
          this.$Notice.success({
            title: '成功',
            desc: `团队${this.formData.teamName}申请成功，请通知管理员进行审批！`
          })
          resolve({
            id: response.data?.teamId,
            name: response.data?.teamName,
            code: response.data?.teamCode
          })
        })
        .catch((err) => {
          reject(new Error('团队申请失败:' + err.message))
        })
    })
  }
}
