
import { Prop, Vue, Component } from 'vue-property-decorator'
import TeamSelector from '@/components/team-selector.vue'
import { BaseModal } from '@/libs/modal-helper'
import { UserTeamInfo } from '@/api/user'
import showCreateOrJoinTeamModal from './create-team-modal'

// 登录后选择团队对话框
@Component({
  name: 'ChooseTeamModal',
  components: { TeamSelector },
  mixins: []
})
export default class ChooseTeamModal extends Vue implements BaseModal {
  visiable = false;

  /**
    * 用户的团队列表
  */
  @Prop({
    required: true,
    default: () => [],
    type: Array
  })
  userTeamList!: UserTeamInfo[]

  // 用户id
  @Prop({
    required: true,
    type: Number
  })
  userId!: number

  // 当前选择的团队id
  teamId?: number=undefined

  // 用户选择与默认的团队id
  @Prop({
    required: false,
    type: Number
  })
  deafaultTeamId?: number

  // 当前的用户团队列表
  curUserTeamList!: UserTeamInfo[]

  // 组件创建事件
  created () {
    this.teamId = this.deafaultTeamId
    this.curUserTeamList = this.userTeamList
  }

  /**
   * 创建或加入团队
   */
  doCreateOrJoinTeam (createMode: boolean) {
    const that = this
    showCreateOrJoinTeamModal(this.userId, createMode)
      .then((team) => {
        that.curUserTeamList.push({
          teamId: team.id!,
          teamName: team.name!,
          teamCode: team.code!
        })
        // 弹制组件更新，以让列表更新
        this.teamId = team.id
        that.$forceUpdate()
      })
      .catch((err) => {
        this.$Message.error(`${createMode ? '创建' : '申请加入'}团队失败:` + err.message)
      })
  }

  show (): void {
    this.visiable = true
  }

  close (): void {
    this.visiable = false
  }

  // 对话框保存
  doSave (): void {
    if (!this.teamId) {
      this.$Message.error('请选择一个要登入的团队')
      return
    }
    const teamCode = this.userTeamList.filter((item) => {
      return item.teamId === this.teamId
    })[0].teamCode

    this.$emit('onOk', { teamCode: teamCode, teamId: this.teamId })
  }

  // 取消对话框
  doCancel (): void {
    this.$emit('onCancel')
  }
}
