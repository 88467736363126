import { showModal } from '@/libs/modal-helper'
import modal from './forget-password-modal.vue'

/**
  * 显示忘记密码的弹出对话框
  * @returns
  */
export default function showForgetPasswordModal (): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    showModal<void>(modal, {
      props: {}
    }, true, () => {
      resolve()
      return new Promise<void>((resolve, reject) => resolve())
    }, () => {
      reject(new Error('用户取消'))
    })
  })
}
