/**
 * 选择团队对话框
 */
import { UserTeamInfo } from '@/api/user'
import { showModal } from '@/libs/modal-helper'
import modal from './choose-team-modal.vue'
import localStorage from '@/libs/local-storage'

/**
 * 显示选择团队的弹出对话框
 * @param userId
 * @returns
 */
export default function showSelectTeamModal (userId: number, userTeamList: UserTeamInfo[]): Promise<string> {
  const teamId = localStorage.loadUserLastTeamId()
  return new Promise<string>((resolve, reject) => {
    showModal<any>(modal, {
      props: {
        userTeamList: userTeamList,
        deafaultTeamId: teamId,
        userId: userId
      }
    }, true, ({ teamCode, teamId }) => {
      resolve(teamCode)
      localStorage.saveUserLastTeamId(teamId)
      return new Promise<void>((resolve, reject) => resolve())
    }, () => {
      reject(new Error('用户取消'))
    })
  })
}
