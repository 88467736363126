
import { Team } from '@/api/team'
import { showModal } from '@/libs/modal-helper'
import modal from './create-team-modal.vue'

/**
  * 显示创建团队的弹出对话框
  * @param userId
  * @param createMode 为真时是创建模式,否则是加入模式
  * @returns
  */
export default function showCreateOrJoinTeamModal (userId: number, createMode: boolean): Promise<Team> {
  return new Promise<Team>((resolve, reject) => {
    showModal<Team>(modal, {
      props: {
        userId: userId,
        isCreateMode: createMode
      }
    }, true, (team) => {
      resolve(team)
      return new Promise<void>((resolve, reject) => resolve())
    }, () => {
      reject(new Error('用户取消'))
    })
  })
}
